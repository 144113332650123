/* chatinputs.css */

.chat-input {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  font-size: 24px;
  cursor: pointer;
  margin: 0 5px;
  color: #666;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 015 , 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.popup-container h2 {
  margin-top: 0;
}

.popup-container button {
  background-color: #4CAF50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.popup-container button:hover {
  background-color: #3e8e41;
}

.text-input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #f0f0f0;
  margin: 0 5px;
  font-size: 16px;
}

.text-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.audio-preview {
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.fileviewertitle {
  /* height: 100%; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-content: center;
  margin-top: 18px;
}
.fileviewer {
  /* height: 100%; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-content: center;
  margin-top: 5%;
}
.preview-container {
  position: fixed;
  bottom: 20%;
  /* right: 12%; */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px; 
  padding: 20px;
  display: flex;
  align-items: center;
  
}
.preview-buttons{
  margin-top: 20px;

}

.preview-container img {
  max-width: 100%;
  max-height: 100%;
  /* height: 100px;
  width: 120px; */
  margin-right: 15px;
  bottom: 20%
}

.preview-file {
  margin:0px;

  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* margin-right: 10px; */
  bottom: 20%;
  
}

.send-button,
.close-button {
  padding: 5px 10px;
  margin-right: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover,
.close-button:hover {
  background-color: #0056b3;
}

.popup-modal {
  position: fixed;
  bottom: 4%;
  right: 12%;
  transform: translate(-50%, -50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 8px;
  z-index: 1000;
}

.popup-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
