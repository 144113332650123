.audio-container {
  position: relative;
  display: inline-block;
  width: 30%;
  background-color: aliceblue;
  
}

.audio {
  width: 100%;
}

.download-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.audio-container:hover .download-btn {
  opacity: 1;
}

.controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  
}

.controls button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin: 0 5px;
  color: black;
}

.controls button:hover {
  color: goldenrod;
}

.timestamp {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 0.8em;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 5px;
  border-radius: 3px;
}

/* Progress bar styles */
.progress-container {
  width: 100%;
  height: 5px; /* Adjust the height of the progress bar */
  background-color: #ddd; /* Background color of the progress bar */
  margin-top: 5px; /* Add some spacing between controls and progress bar */
}

.progress {
  height: 100%;
  width: 0;
  background-color: goldenrod; /* Progress color */
  transition: width 0.3s ease;
}
